body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markdownContainer table thead tr th {
  padding-left: 10px;
  padding-right: 10px;
}

.voteA {
  font-weight: bold;
  background-color: rgba(97, 218, 251, 0.27);
}

.voteB {
  font-weight: bold;
  background-color: rgba(251, 97, 97, 0.27);
}

.voteC {
  font-weight: bold;
  background-color: rgba(0, 255, 22, 0.27);
}

.voteApruebo {
  font-weight: bold;
  background-color: rgba(97, 218, 251, 0.27);
}

.voteRechazo {
  font-weight: bold;
  background-color: rgba(251, 97, 97, 0.27);
}
